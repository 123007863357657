import { RiFileCopyLine } from 'react-icons/ri';
import { useGlobalContext } from '../../context/context';

const Copy = ({ target }) => {
  const { handleCopy } = useGlobalContext();
  return (
    <div className='copy'>
      <RiFileCopyLine
        onClick={() => {
          handleCopy(target, 'Seed Phrase copied successfully');
        }}
        className='copy-btn'
      />
    </div>
  );
};

export default Copy;
