import { assets } from '../../data/data';
import { useGlobalContext } from '../../context/context';
import { useEffect } from 'react';

const CryptoGuides = () => {
  const { resetPage } = useGlobalContext();

  useEffect(() => {
    resetPage(`Crypto Guides`);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className='guides'>
        <div className='block'>
          <h1>Bitcoin and cryptocurrency for beginners</h1>
          <p>
            Master the basics with Malroc AI Trades&apos; Crypto Guides, where
            we take an in-depth look at some of the top projects in the space so
            you can be informed before you invest.
          </p>
        </div>
        <div className='assets'>
          <h2>Our most popular assets</h2>
          <br />
          <div className='assets-container'>
            {assets.map((asset, index) => {
              const { logo, name, info } = asset;
              return (
                <article className='card' key={index}>
                  <div className='logo'>
                    <img src={logo} alt={name} />
                  </div>
                  <h3>{name}</h3>
                  <p>{info}</p>
                </article>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default CryptoGuides;
