import { useGlobalContext } from '../../context/context';
import { useEffect } from 'react';
import parse from 'html-react-parser';

const StocksGuides = () => {
  const { resetPage } = useGlobalContext();

  useEffect(() => {
    resetPage(`Crypto Guides`);
    // eslint-disable-next-line
  }, []);

  const beginnerTips = [
    {
      title: `Selecting the Right Investment`,
      desc: `<p>Choosing the right stock is far more challenging than it sounds. While it's easy to identify a stock that has performed well in the past, predicting its future performance is considerably harder. To succeed in investing in individual stocks, you need to commit to thoroughly analyzing a company and managing your investment.</p><p>
Dan Keady, CFP and chief financial planning strategist at TIAA, emphasizes, “When you start examining statistics, keep in mind that professionals scrutinize each company with far more diligence than most individuals can manage, making it a tough game for individuals to win consistently over time.”</p><p>When evaluating a company, it's crucial to consider its fundamentals, such as earnings per share (EPS) or price-earnings ratio (P/E ratio). However, the analysis goes beyond that; you must also assess the management team, competitive advantages, and financial statements, including the balance sheet and income statement, among other factors.</p><p>Keady advises against buying stocks based solely on personal preference or past performance, as this offers no assurance of future success. You must analyze the company and anticipate future developments, a challenging task even during favorable conditions.</p>`,
    },
    {
      title: `Establish a Diversified Portfolio`,
      desc: `<p>One of the significant benefits of an index fund is that it provides instant exposure to a variety of stocks. For instance, if you invest in a broadly diversified fund linked to the S&P 500, you'll hold shares in hundreds of companies across various industries. Alternatively, you might choose a narrowly focused fund that concentrates on one or two sectors.</p><p>Diversification is vital as it minimizes the risk that any single stock will adversely affect the overall performance of your portfolio, ultimately enhancing your returns. In contrast, investing in only one stock puts all your resources in one place.</p><p>The simplest way to build a broad portfolio is by investing in an ETF or mutual fund, which inherently provides diversification without requiring extensive analysis of individual companies.</p><p>“It may not be the most thrilling approach, but it's an excellent starting point,” Keady remarks. “It helps prevent the misconception that you can outsmart the market by picking stocks that will rise without dropping and knowing precisely when to buy and sell.”</p><p>When discussing diversification, it's essential to note that it involves not just a variety of stocks but also investments spread across different industries—since stocks in similar sectors can move in tandem due to common factors.</p>`,
    },
    {
      title: `Stay Committed to Your Long-Term Portfolio`,
      desc: `<p>Keady emphasizes that investing should be viewed as a long-term endeavor. He advises distancing yourself from the daily financial news cycle.</p><p>By avoiding constant exposure to financial news, you can cultivate the patience required for long-term investing. It's also beneficial to minimize how often you check your portfolio, preventing excessive anxiety or excitement—especially useful for beginners still learning to manage their emotions when investing.</p><p>“Certain news cycles can become overwhelmingly negative,” Keady warns.</p><p>One effective strategy for beginners is to establish a schedule to evaluate your portfolio periodically. Adhering to this guideline will help prevent impulsive decisions during market volatility, ensuring you reap the benefits of well-performing investments, Keady adds.</p>`,
    },
    {
      title: `Start Investing Now`,
      desc: `<p>Waiting for the perfect moment to invest in the stock market often proves unproductive. No one can predict with absolute certainty when the best time to enter is. Since investing is meant to be a long-term pursuit, there's no ideal moment to begin.</p><p>“One of the key principles of investing is to take action rather than just thinking about it,” Keady asserts. “Start now. Investing early and consistently over time harnesses the power of compounding, which can significantly enhance your results. If you aim to invest, it's crucial to take the first step and establish an ongoing savings program to achieve your goals over time.”</p>`,
    },
    {
      title: `Consistently Invest Over Time`,
      desc: `<p>It's tempting to invest your money and assume your job is complete. However, those who accumulate real wealth do so incrementally by continuously adding funds to their investments. This necessitates a disciplined saving approach—allocating a portion of your paycheck for investment purposes. By doing so, you can invest more and accelerate your wealth growth.</p><p>If you have a 401(k) retirement plan, you may already be implementing this strategy, as it automatically deducts money from your biweekly paycheck to invest in your chosen assets. Even if you don't have a 401(k), many brokerage accounts allow for automatic transfers to your investment account, which can facilitate regular investments through a broker or a top robo-advisor.</p><p>
Automating your investment process helps to minimize emotional decision-making.</p>`,
    },
  ];

  return (
    <>
      <section className='guides'>
        <div className='block'>
          <h1>Stock Market 101: 6 Essential Tips for Beginners</h1>
          <p>
            Movies, TV shows, and news often assume everyone is familiar with
            how the stock market operates. While it's widely recognized that
            there's potential to make substantial money if you know what you're
            doing, many beginners struggle to grasp the market's inner workings
            and why stock prices rise or fall. Before you dive into investing,
            here's a breakdown of what you need to understand about the stock
            market.
          </p>
        </div>

        <div className='block'>
          <h2>What is the stock market?</h2>

          <p>
            Stocks, also known as equities, are financial instruments that grant
            shareholders a portion of ownership in a publicly traded company.
            This ownership gives investors a genuine stake in the company, and
            those who hold a majority of the shares can influence the company's
            operations. The stock market is essentially a marketplace where
            these shares can be bought and sold by the public through various
            exchanges.
          </p>

          <p>
            So, where do stocks come from? Public companies issue shares to
            raise capital for their business ventures. Investors who believe in
            the company's future growth purchase these shares. In return,
            shareholders may benefit from dividends and an increase in the
            stock's price. However, there's also a risk—if the company falters
            or goes bankrupt, shareholders could see their investment decrease
            or disappear entirely.
          </p>

          <p>
            The stock market operates as an aftermarket, where existing
            shareholders can sell their stocks to other investors. This trading
            happens on exchanges like the New York Stock Exchange or Nasdaq.
            While trading once took place on physical trading floors, today,
            nearly all stock trades are executed electronically.
          </p>

          <p>
            When you hear news reports saying, "the market was up today,"
            they're usually referring to indexes like the Standard & Poor's 500
            (S&P 500) or the Dow Jones Industrial Average. The S&P 500 tracks
            around 500 large U.S. companies, while the Dow consists of 30 major
            corporations. These indexes gauge the performance of a segment of
            the market, showing how these collections of stocks performed on a
            particular day or over time.
          </p>

          <p>
            However, it's important to note that while people often refer to the
            Dow and S&P 500 as "the market," these are just indexes representing
            a small portion of the total stock market, which includes thousands
            of publicly traded companies.
          </p>

          <p>
            Before diving into stock investments, you'll need to open a
            brokerage account. As you begin, here are eight more essential
            guidelines for investing in the stock market.
          </p>
        </div>

        <div className='block'>
          <h2>How to Start Investing in Stocks: 6 Beginner-Friendly Tips</h2>

          <ol>
            {beginnerTips.map((tip, index) => {
              return <li key={index}>{tip.title}</li>;
            })}
          </ol>
        </div>

        {beginnerTips.map((tip, index) => {
          return (
            <div className='block' key={index}>
              <h2>{`${index + 1}. ${tip.title}`}</h2>
              {parse(tip.desc)}
            </div>
          );
        })}
      </section>
    </>
  );
};

export default StocksGuides;
