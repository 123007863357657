import { useState, useEffect } from 'react';
import { useGlobalContext } from '../../context/context';
import { RiFileCopyLine } from 'react-icons/ri';
import Bitcoin from '../../assets/bitcoin.svg';
import Ethereum from '../../assets/ethereum-eth-logo.svg';
import USDT from '../../assets/tether-usdt-logo.svg';
import trx from '../../assets/trx-logo.svg';
import solana from '../../assets/solana-logo.svg';
import { toast } from 'react-toastify';

const Deposit = () => {
  const [depositStep, setDepositStep] = useState(1);
  const { setIsUser, setUserToken, resetPage, fetchUser, userToken } =
    useGlobalContext();

  useEffect(() => {
    setUserToken(JSON.parse(window.localStorage.getItem(`userToken`)));
  }, [setUserToken]);

  useEffect(() => {
    if (window.localStorage.getItem(`userDetails`)) {
      setIsUser(true);
    }
  }, [setIsUser]);

  useEffect(() => {
    resetPage(`Deposit`);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userToken) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, [userToken]);

  return (
    <section className='deposit'>
      <section className='deposit-block'>
        {depositStep === 1 ? (
          <StepOne setDepositStep={setDepositStep} />
        ) : (
          <StepTwo />
        )}
      </section>
    </section>
  );
};

const StepOne = ({ setDepositStep }) => {
  const { setDepositAmount, depositAmount, isNavbar } = useGlobalContext();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDepositAmount({ ...depositAmount, [name]: parseInt(value) });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <article className='deposit-info'>
      <h3>Make Deposit</h3>
      <form onSubmit={handleSubmit} className='deposit-form'>
        {/* DEPOSIT AMOUNT */}
        <div className='form-control'>
          <label
            htmlFor='amount'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Enter deposit amount
          </label>
          <br /> <br />
          <input
            type='number'
            placeholder='Enter an amount'
            id='amount'
            className='input'
            name='amount'
            value={depositAmount.amount}
            onChange={handleChange}
            required
          />
        </div>

        {/* MAKE DEPOSIT BUTTON */}
        <div className='submit-btn'>
          <button
            className='gold'
            onClick={() => {
              if (depositAmount.amount > 0) {
                setDepositStep(2);
              }
            }}
          >
            Make Deposit
          </button>
        </div>
      </form>
    </article>
  );
};

const StepTwo = () => {
  const { userToken, depositAmount, baseURL, userDetails } = useGlobalContext();
  const [activeAddress, setActiveAddress] = useState(null);

  const sendDepositRequest = async () => {
    try {
      const response = await fetch(`${baseURL}/transaction/add`, {
        method: `POST`,
        body: JSON.stringify(depositAmount),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.json();
    } catch (error) {
      console.log(error);
    }
  };

  const walletAddresses = [
    {
      name: `Bitcoin`,
      logo: Bitcoin,
      defaultAddress: `bc1q4s80ajj80gn54krae43ymkfw3zvh0lsyv78x4t`,
      dynamicAddress: userDetails.bitcoinAddress,
    },
    {
      name: `Ethereum`,
      logo: Ethereum,
      defaultAddress: `0x9CF6C9DEC6e4b4E30caC8d6045d55EB0f4152E44`,
      dynamicAddress: userDetails.ethereumAddress,
    },
    {
      name: `USDT`,
      logo: USDT,
      defaultAddress: `TM2gu3yDTToJ4PDhaFWAutkr2Z37odDwoe`,
      dynamicAddress: userDetails.usdtAddress,
    },
    {
      name: `TRX`,
      logo: trx,
      defaultAddress: `TM2gu3yDTToJ4PDhaFWAutkr2Z37odDwoe`,
      dynamicAddress: ``,
    },
    {
      name: `Solana`,
      logo: solana,
      defaultAddress: `3xNnirKanVPSJrrZChM2Pjbi9mvHHMFMZzTpfSedSPDC`,
      dynamicAddress: ``,
    },
  ];

  return (
    <article className='receipt'>
      <h1>
        Please select a payment method and make a deposit to the provided wallet
        address.
      </h1>

      {walletAddresses.map((address, index) => {
        const { defaultAddress, dynamicAddress, logo, name } = address;
        return (
          <div className='method' key={index}>
            <div
              className='crypto'
              onClick={() => {
                setActiveAddress(index);
                if (activeAddress === index) {
                  setActiveAddress(null);
                }
              }}
            >
              <img src={logo} alt={`${name} logo`} />
              <h4>{name}</h4>
            </div>
            {activeAddress === index && (
              <div className='wallet-address'>
                <p>
                  {dynamicAddress.length < 2 ? defaultAddress : dynamicAddress}
                </p>
                <RiFileCopyLine
                  className='copy-btn'
                  onClick={() => {
                    navigator.clipboard.writeText(
                      dynamicAddress.length < 2
                        ? defaultAddress
                        : dynamicAddress
                    );
                    toast.success(`Address copied to clipboard`);
                  }}
                />
              </div>
            )}
          </div>
        );
      })}

      <p>
        After sucessfully making payment, ensure to submit payment receipt via
        email by clicking on the button below.
      </p>
      <a href='mailto: support@malrocaitrades.com?subject=Deposit Receipt'>
        <button
          className='gold'
          onClick={() => {
            setTimeout(() => {
              sendDepositRequest();
            }, 2000);
          }}
        >
          Upload Payment Receipt
        </button>
      </a>
    </article>
  );
};

export default Deposit;
